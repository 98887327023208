import type { PopularRoutesType } from '@redux/features/homePage/types/popularRoutes'

import type { TabsOptions } from '@base/tabs/Tabs'

export const getTabOptions = (popularRoutes?: PopularRoutesType): TabsOptions => {
  if (!popularRoutes) return []
  const options = popularRoutes.map(route => {
    if (route.below) {
      return { key: route.key, label: route.title }
    }
  })

  return options.filter(option => option) as TabsOptions
}
