import { type FC, useEffect, useRef } from 'react'
import styled from 'styled-components'

import type { PopularRoutesType } from '@redux/features/homePage/types/popularRoutes'

import Route from '@components/mainPageBlocks/PopularRoutes/listRoutes/route/Route'
import ShowMoreButton from '@components/mainPageBlocks/PopularRoutes/listRoutes/showMoreButtom/ShowMoreButtom'
import useToggle from '@hooks/useToggle/useToggle'

type Props = {
  activeTab: string
  popularRoutesList?: PopularRoutesType
}

//TODO tab and mobile horizontal scroll??
const ListRoutes: FC<Props> = ({ activeTab, popularRoutesList }) => {
  const { on: isExpanded, toggle: toggleIsExpanded, toggleOff: toggleOffIsExpanded } = useToggle()
  const { on: isNeedExtend, toggleOff: toggleOffIsNeedExtend, toggleOn: toggleOnIsNeedExtend } = useToggle()
  const refContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    toggleOffIsExpanded()
  }, [activeTab])

  useEffect(() => {
    const activeList = refContainer.current?.querySelector('.active')
    if ((activeList?.scrollHeight || 0) > (refContainer?.current?.clientHeight || 0)) {
      toggleOnIsNeedExtend()
    } else {
      toggleOffIsNeedExtend()
    }
  }, [activeTab, toggleOnIsNeedExtend, toggleOffIsNeedExtend])

  return (
    <Container>
      <div ref={refContainer}>
        {popularRoutesList?.map(({ below, key }) => {
          const isActive = activeTab === key

          return (
            <List $isExpanded={isExpanded} className={isActive ? 'active' : ''} key={key}>
              {below.map(route => (
                <Route href={route.relative} key={route.key} title={route.title} />
              ))}
            </List>
          )
        })}
      </div>
      {isNeedExtend && <ShowMoreButton isExpanded={isExpanded} onClick={toggleIsExpanded} />}
    </Container>
  )
}

const Container = styled.div`
  /*  ${p => p.theme.mediaQueries.mobileTablet} {
    &:not(:has(.active li:nth-child(11))) button {
      display: none;
    }
  }

  ${p => p.theme.mediaQueries.onlyDesktopS} {
    &:not(:has(.active li:nth-child(16))) button {
      display: none;
    }
  }

  ${p => p.theme.mediaQueries} {
    &:not(:has(.active li:nth-child(25))) button {
      display: none;
    }
  }*/

  ${p => p.theme.mediaQueries.mobile} {
    border-top: 1px solid ${p => p.theme.colors.border};
  }

  ${p => p.theme.mediaQueries.noMobile} {
    border: 1px solid ${p => p.theme.colors.border};
    border-radius: ${p => p.theme.borderRadius.default};
  }
`

const List = styled.ul<{ $isExpanded: boolean }>`
    padding: 32px 80px 15px;
    display: none;
    grid-template-columns: repeat(4, 1fr);
    max-height: ${p => (p.$isExpanded ? 2000 : 287)}px;
    list-style-type: none;
    overflow: hidden;
    transition: ${p => p.theme.transitions.default};
    
    &.active {
        display: grid;
        grid-column-gap: 20px;
    }

    ${p => p.theme.mediaQueries.desktopS} {
        grid-template-columns: repeat(3, 1fr);
        max-height: ${p => (p.$isExpanded ? 2000 : 247)}px;
    }

    ${p => p.theme.mediaQueries.mobileTablet} {
        padding: 32px 40px 15px;
        grid-template-columns: repeat(2, 1fr);
    }
}
`

export default ListRoutes
