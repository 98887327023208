import type { FC } from 'react'
import styled from 'styled-components'

import SimpleTabs from '@base/tabs/SimpleTabs/SimpleTabs'
import type { TabsOptions } from '@base/tabs/Tabs'

type Props = {
  activeTabKey: string
  setActiveTab: (activeTab: string) => void
  tabsOptions: TabsOptions
}

const HeaderTabs: FC<Props> = ({ activeTabKey, setActiveTab, tabsOptions }) => {
  return (
    <Container>
      <SimpleTabs activeTabKey={activeTabKey} options={tabsOptions} setActiveTab={setActiveTab} />
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: -1px;
  margin-inline: 80px;

  .ant-tabs-nav-wrap {
    &::before,
    &::after {
      box-shadow: none !important;
    }
  }

  ${p => p.theme.mediaQueries.tablet} {
    margin-inline: 40px;
  }

  ${p => p.theme.mediaQueries.mobileTablet} {
    width: 100%;

    .ant-tabs-tab {
      margin: 0 20px !important;
    }

    .ant-tabs-top > .ant-tabs-nav {
      padding: 0;
    }
  }

  ${p => p.theme.mediaQueries.mobile} {
    margin-inline: 16px;
  }
`

export default HeaderTabs
