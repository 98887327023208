import styled from 'styled-components'

import Button from '@base/buttons/Button'
import { captionMediumStyles } from '@styles/typography/caption'

export const TabsWrapper = styled.ul`
  list-style-type: none;
  display: flex;
  gap: 40px;
  padding: 14px 0;
`

export const StyledTab = styled.li<{ $isActive: boolean }>`
  position: relative;

  &::before {
    content: '';
    opacity: ${p => (p.$isActive ? 1 : 0)};
    transition: ${p => p.theme.transitions.default};
    position: absolute;
    left: 0;
    right: 0;
    bottom: -14px;
    height: 4px;
    border-radius: 2px;
    background-color: ${p => p.theme.colors.primary};
  }
`

export const StyledTabButton = styled(Button)`
  &.ant-btn-link {
    ${captionMediumStyles};
    color: ${p => p.theme.colors.fontSecondary};
  }
`
