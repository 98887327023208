import Link from 'next/link'
import type { FC } from 'react'
import styled from 'styled-components'

import { textMediumRegularStyles } from '@styles/typography/text'

type Props = {
  href: string
  title: string
}

const Route: FC<Props> = ({ href, title }) => {
  return (
    <Container>
      <LinkStyled href={href} prefetch={false}>
        {title}
      </LinkStyled>
    </Container>
  )
}

const Container = styled.li`
  margin-bottom: 16px;
  white-space: nowrap;
`

const LinkStyled = styled(Link)`
  ${textMediumRegularStyles};
  white-space: break-spaces;
  color: ${p => p.theme.colors.fontPopularRoutes};

  &:hover {
    text-decoration: underline;
    color: ${p => p.theme.colors.primary};
  }
`

export default Route
