import type { ButtonProps } from 'antd/es'
import { Button } from 'antd/es'
import type { FC, ReactNode } from 'react'
import styled from 'styled-components'

import { title7Styles } from '@styles/typography/title'

type Props = {
  children?: ReactNode
} & ButtonProps

const LinkButton: FC<Props> = ({ children, ...props }) => {
  return (
    <StyledButton {...props} type="link">
      {children}
    </StyledButton>
  )
}

export default LinkButton

const StyledButton = styled(Button)<ButtonProps>`
  &.ant-btn.ant-btn-link {
    ${title7Styles};
    padding: 0;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    span {
      color: ${p => p.theme.colors.primary};
    }

    svg {
      margin-inline-start: 16px;
      fill: ${p => p.theme.colors.primary};
    }

    &:hover {
      text-decoration: underline;
      color: ${p => p.theme.colors.primary};
    }

    &:disabled {
      opacity: 0.3;
      text-decoration: none;
    }

    &:focus {
      span {
        text-decoration: underline;
      }
    }
  }
`
