import type { FC } from 'react'
import { memo } from 'react'

import { StyledTab, StyledTabButton, TabsWrapper } from './styles'

export type TabsOptions = Array<{ key: string; label: string }>

type Props = {
  activeTabKey: string
  options: TabsOptions
  setActiveTab: (activeTab: string) => void
}

const SimpleTabs: FC<Props> = ({ activeTabKey, options, setActiveTab }) => {
  return (
    <TabsWrapper>
      {options.map(tab => {
        const isActive = tab.key === activeTabKey

        return (
          <StyledTab $isActive={isActive} key={tab.key}>
            <StyledTabButton onClick={isActive ? undefined : () => setActiveTab(tab.key)} type="link">
              {tab.label}
            </StyledTabButton>
          </StyledTab>
        )
      })}
    </TabsWrapper>
  )
}

export default memo(SimpleTabs)
