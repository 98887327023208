import { useTranslation } from 'next-i18next'
import type { FC } from 'react'
import styled from 'styled-components'

import LinkButton from '@base/buttons/link/LinkButton'
import { UilArrowDown } from '@iconscout/react-unicons'

type Props = {
  isExpanded: boolean
  onClick: () => void
}
const ShowMoreButton: FC<Props> = ({ isExpanded, onClick }) => {
  const { t } = useTranslation('Popular routes')

  return (
    <Container $isExpanded={isExpanded} color="primary" onClick={onClick}>
      {t('ShowMorePopularRoutes')} <UilArrowDown />
    </Container>
  )
}

const Container = styled(LinkButton)<{ $isExpanded: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 65px;
  border-top: 1px solid ${p => p.theme.colors.border};
  border-radius: 0;
  text-transform: uppercase;

  ${p => p.theme.mediaQueries.mobile} {
    border-radius: ${p => p.theme.borderRadius.default};
    border: 1px solid ${p => p.theme.colors.border};
    width: -moz-available;
    width: -webkit-fill-available;
    margin: 0 20px;
  }

  svg {
    transform: rotate(${p => (p.$isExpanded ? '180deg' : '0')});
    transition: ${p => p.theme.transitions.default};
  }
`

export default ShowMoreButton
